import React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <article>
        <header className="mb-4">
          <h1 className="text-3xl font-semibold">{post.frontmatter.title}</h1>
          <p className="text-sm text-gray-500">{post.frontmatter.date}</p>
        </header>
        <section
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <section className="flex items-center ">
          <hr className="flex-1" />
          <i className="mx-4 text-sm text-gray-600">EOF</i>
          <hr className="flex-1" />
        </section>
        <footer className="mt-8">
          <Bio />
        </footer>
      </article>

      <nav className="flex flex-col sm:flex-row my-20">
        <div className="flex flex-grow max-w-full sm:max-w-1/2">
          {previous && (
            <Link
              className="block flex-grow border border-solid border-gray-400 hover:border-purple-400 rounded p-4"
              to={previous.fields.slug}
              rel="prev"
            >
              <div className="text-sm text-gray-600 font-medium mb-1">
                上一篇
              </div>
              <div className="text-purple-500 font-semibold">
                « {previous.frontmatter.title}
              </div>
            </Link>
          )}
        </div>
        <div className="flex flex-grow max-w-full sm:max-w-1/2 sm:text-right mt-4 sm:mt-0 sm:ml-4">
          {next && (
            <Link
              className="block flex-grow border border-solid border-gray-400 hover:border-purple-400 rounded p-4"
              to={next.fields.slug}
              rel="prev"
            >
              <div className="text-sm text-gray-600 font-medium mb-1">
                下一篇
              </div>
              <div className="text-purple-500 font-semibold">
                {next.frontmatter.title} »
              </div>
            </Link>
          )}
        </div>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY 年 MM 月 DD 日")
        description
      }
    }
  }
`;
